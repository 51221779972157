import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzGridModule } from 'ng-zorro-antd/grid';
import moment from 'moment-timezone';
import { url } from './url';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NzIconModule,
    NzLayoutModule,
    NzMenuModule,
    NzGridModule,
    NzDropDownModule,
    HttpClientModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  public title: string = '';
  public year: string = moment().format('YYYY');
  public currentType = '';
  public typeOptions: any = url;
  public ipAddress = '';
  public ipAllowList = [
    '103.165.33.2',
    '103.165.33.210',
    '103.167.35.8',
    '15.235.182.147',
  ];

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentType = event.url ?? '/departure-arrival';
        if (event.url) {
          this.showType();
        }
      }
    });
  }

  ngOnInit(): void {
    setInterval(() => {
      setTimeout(this.showTime, 100);
    }, 1000);
    this.showType();
    this.getIpAddress();
  }

  showType() {
    const data = this.typeOptions.find((e: any) => e.key === this.currentType);
    if (data) {
      this.title = data.label;
    } else {
      this.title = 'Home';
    }
  }

  changeType(type: string) {
    this.currentType = type;
    this.router.navigateByUrl(type);
    this.showType();
  }

  showTime() {
    let date = new Date();
    let fullDate = moment()
      .locale('id')
      .tz('Asia/Jakarta')
      .format('DD MMMM YYYY');

    let h: any = date.getHours();
    let m: any = date.getMinutes();
    let s: any = date.getSeconds();
    /* var session = "AM"; */

    if (h == 0) {
      h = 12;
    }

    // if(h > 12){
    //     h = h - 12;
    //     session = "PM";
    // }

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;

    var time = fullDate + '  |  ' + h + ':' + m + ':' + s;
    const clockDisplay = document.getElementById('clock-display');
    if (clockDisplay) {
      clockDisplay.innerText = time;
      clockDisplay.textContent = time;
    }
  }

  getIpAddress() {
    this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }
}
