<div *ngIf="!ipAllowList.includes(ipAddress)" class="flex-container">
  <h2>Forbidden</h2>
  <p>Your IP Address {{ ipAddress }}</p>
</div>

<nz-layout class="layout" *ngIf="ipAllowList.includes(ipAddress)">
  <nz-header>
    <div style="display: flex; width: 100%">
      <div class="flex-center" style="width: 50%">
        <img
          style="margin: 9px 9px 25px 9px; height: 43px"
          src="assets/images/logo-armas.png"
          alt="logo"
        />
        <h2
          style="color: white; cursor: pointer"
          nz-dropdown
          [nzDropdownMenu]="menu"
        >
          {{ title }}
          <span nz-icon nzType="down"></span>
        </h2>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li
              nz-menu-item
              *ngFor="let view of typeOptions"
              (click)="changeType(view.key)"
            >
              {{ view.label }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
      <div class="flex-center" style="width: 50%; justify-content: end">
        <span
          style="
            color: white;
            font-size: 25px;
            display: inline-block;
            margin-bottom: 14px;
          "
          nz-icon
          nzType="calendar"
          nzTheme="outline"
        ></span>
        <h2
          style="margin-right: 20px; margin-left: 10px; display: inline-block"
        >
          <div
            style="color: white"
            id="clock-display"
            class="clock"
            onload="showTime()"
          ></div>
        </h2>
      </div>
    </div>
  </nz-header>
  <nz-content>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <!-- <nz-footer
    >PT Armas Logistic Service ©{{ year }} Developed By Jiwalu Studio</nz-footer
  > -->
</nz-layout>
